/* Custom Neon Effect */
@keyframes neon-blink {
    0% {
      text-shadow: 0 0 10px #f0c674, 0 0 20px #f0c674, 0 0 30px #f0c674, 0 0 40px #f0c674, 0 0 50px #f0c674, 0 0 60px #f0c674, 0 0 70px #f0c674;
    }
    50% {
      text-shadow: 0 0 20px #f0c674, 0 0 30px #f0c674, 0 0 40px #f0c674, 0 0 50px #f0c674, 0 0 60px #f0c674, 0 0 70px #f0c674, 0 0 80px #f0c674;
    }
    100% {
      text-shadow: 0 0 10px #f0c674, 0 0 20px #f0c674, 0 0 30px #f0c674, 0 0 40px #f0c674, 0 0 50px #f0c674, 0 0 60px #f0c674, 0 0 70px #f0c674;
    }
  }
  
  .neon-icon {
    color: #f0c674; /* Gold color */
    animation: neon-blink 1.5s infinite alternate;
  }
  
  .neon-text {
    color: #f0c674; /* Gold color */
    text-shadow: 0 0 10px #f0c674, 0 0 20px #f0c674, 0 0 30px #f0c674, 0 0 40px #f0c674;
  }

  /* Disable text selection for the entire website */
* {
  user-select: none; /* For modern browsers */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(0); }
  75% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.5s ease-in-out;
}

/* Add this CSS to your global stylesheet or a CSS module */
.marquee-container {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%; /* Pushes the content to start from the right side */
  animation: marquee 25s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

:root {
	--PhoneInput-color--focus: #03b2cb;
}