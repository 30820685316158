/* Global reset for focus outline on all inputs */
input:focus {
	outline: none; /* Removes default outline */
  }
  
  /* Keyframes for gradient animation */
  @keyframes gradient-x {
	0% {
	  background-position: 0% 50%;
	}
	50% {
	  background-position: 100% 50%;
	}
	100% {
	  background-position: 0% 50%;
	}
  }
  
  /* CSS variables */
  :root {
	  --PhoneInput-color--focus: transparent;
	  --PhoneInputInternationalIconPhone-opacity: 0.8;
	  --PhoneInputInternationalIconGlobe-opacity: 0.65;
	  --PhoneInputCountrySelect-marginRight: 0.35em;
	  --PhoneInputCountrySelectArrow-width: 0.3em;
	  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	  --PhoneInputCountrySelectArrow-borderWidth: 1px;
	  --PhoneInputCountrySelectArrow-opacity: 0.45;
	  --PhoneInputCountrySelectArrow-color: currentColor;
	  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
	  --PhoneInputCountryFlag-aspectRatio: 1.5;
	  --PhoneInputCountryFlag-height: 1em;
	  --PhoneInputCountryFlag-borderWidth: 1px;
	  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0);
	  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0);
  }
  
  .PhoneInput {
	  /* This is done to stretch the contents of this component. */
	  display: flex;
	  align-items: center;
  }
  
  .PhoneInputInput {
	  /* The phone number input stretches to fill all empty space */
	  flex: 1;
	  /* The phone number input should shrink to make room for the extension input */
	  min-width: 0;
	  /* Ensure no border or box-shadow on focus */
	  border: none; /* Explicitly remove border */
	  box-shadow: none; /* Remove box-shadow */
  }
  
  .PhoneInputInput:focus {
	  border: none; /* Ensure no border on focus */
	  box-shadow: none; /* Ensure no box-shadow on focus */
	  outline: none; /* Ensure no outline on focus */
  }
  
  .PhoneInputCountryIcon {
	  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	  height: var(--PhoneInputCountryFlag-height);
  }
  
  .PhoneInputCountryIcon--square {
	  width: var(--PhoneInputCountryFlag-height);
  }
  
  .PhoneInputCountryIcon--border {
	  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		  inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
  }
  
  .PhoneInputCountryIconImg {
	  display: block;
	  width: 100%;
	  height: 100%;
  }
  
  .PhoneInputInternationalIconPhone {
	  opacity: var(--PhoneInputInternationalIconPhone-opacity);
  }
  
  .PhoneInputInternationalIconGlobe {
	  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
  }
  
  /* Styling native country `<select/>`. */
  .PhoneInputCountry {
	  position: relative;
	  align-self: stretch;
	  display: flex;
	  align-items: center;
	  margin-right: var(--PhoneInputCountrySelect-marginRight);
  }
  
  .PhoneInputCountrySelect {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 100%;
	  width: 100%;
	  z-index: 1;
	  border: 0;
	  opacity: 0;
	  cursor: pointer;
  }
  
  .PhoneInputCountrySelect[disabled],
  .PhoneInputCountrySelect[readonly] {
	  cursor: default;
  }
  
  .PhoneInputCountrySelectArrow {
	  display: block;
	  content: '';
	  width: var(--PhoneInputCountrySelectArrow-width);
	  height: var(--PhoneInputCountrySelectArrow-width);
	  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	  border-style: solid;
	  border-color: transparent; /* Set to transparent */
	  border-top-width: 0;
	  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	  border-left-width: 0;
	  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	  transform: var(--PhoneInputCountrySelectArrow-transform);
	  opacity: var(--PhoneInputCountrySelectArrow-opacity);
  }
  
  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	  opacity: 1;
	  color: var(--PhoneInputCountrySelectArrow-color--focus);
  }
  
  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		  inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
  }
  
  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	  opacity: 1;
	  color: var(--PhoneInputCountrySelectArrow-color--focus);
  }
  